// The list is in inverted chronological order.
// First items are the latest.

module.exports = [
  {
    title: 'Aldar Medical Center',
    message: `Aldar Medical Centre is a healthcare facility in Khor Fakkan, Sharjah which offers the highest standards of medical care to be found in the UAE in a confidential and relaxing atmosphere, crafting personalized experiences around a core of customized treatments that restore, preserve and enhance the essential vitality of skin, hair and body.

    UI/UX Design
    Website
    Digital Marketing
    Media Production

    Exceptional content, beautifully executed has the power to rise above the crowd and truly engage with viewers. This is where we come in.
    
    Visit website: aldarmc.ae`,
    
    image: 'images/aldarmc.jpg',
    link: 'https://aldarmc.ae'
  },



  {
    title: 'Pixnt Digital Agency',
    message: `Pixnt Digital Agency is a full-service digital marketing agency dedicated to providing excellence in Web, SEO, Analytics, Conversion, Reputation and Social media. We’re passionate, data-driven, and eager to help your business succeed.
    
    Visit website: pixnt.com`,
    image: 'images/pixnt.jpg',	
    link: 'https://pixnt.com'
  },



  {
    title: 'Joan Gunaco',
    message: `As one of the world’s leading brands of designer wedding dresses and evening wear, Joan Guanco has been making your dreams come to life.
    Demo webiste`,	
    image: 'images/joan.jpg',
    link: 'https://demos.pixnt.com/joan-boutique/'
  },
];
